import moment from "moment";
import { duplicateObject } from "../helpers/helpers";
export class Basechart {
    constructor() {
        this.options = {
            responsive: true,
            maintainAspectRatio: false,
            scales: {
                yAxes: [{
                    ticks: {
                        beginAtZero: true,
                    },
                }, ],
                xAxes: [{
                    ticks: {
                        beginAtZero: true,
                    },
                }, ],
            },
        };
        this.styles = {
            height: "350px",
            width: "500px",
            position: "relative",
        };
        this.line = {
            data: {
                labels: [],
                datasets: [],
            },
            styles: {
                height: "350px",
                width: "100%",
                position: "relative",
            },
        };
    }
}
export class Clientscharts extends Basechart {
    constructor(period) {
        super();
        this.period = period;
        this.labels = [];
        this.stats = [];
        this.processed = [];
        this.list = [];
    }
    mountLabels(dat = duplicateObject(dat)) {
        let labels = [];
        if (this.period === "daily") {
            dat.forEach((item) => {
                item.date = new Date(item.date);
                item.date = moment(item.date).format("L");
                labels.push(item.date);
            });
            labels = labels.filter((val, index, obj) => {
                return obj.indexOf(val) === index;
            });
            labels.sort((a, b) => {
                return new Date(a) - new Date(b);
            });
            return labels;
        } else {
            dat.forEach((item) => {
                item.date = new Date(item.date);
                item.date = moment(item.date).format("L");
                let data = item.date.split("/");
                item.date = data[0] + "/" + data[2];
                labels.push(item.date);
            });
            labels = labels.filter((val, index, obj) => {
                return obj.indexOf(val) === index;
            });
            labels.sort((a, b) => {
                return new Date(a) - new Date(b);
            });
            return labels;
        }
    }
    mountStats(dat) {
        let stats = [];
        dat.forEach((item) => {
            stats.push(item.status);
        });
        stats = stats.filter((val, index, obj) => {
            return obj.indexOf(val) === index;
        });
        return stats;
    }
    mountList(labels, stats, data) {
        this.line.data.labels = labels;
        labels.forEach((dat) => {
            stats.filter((val) => {
                if (val === 100)
                    this.list.push({
                        data: dat,
                        status: val,
                        label: "Processadas",
                        contagem: [],
                    });
            });
        });
        if (this.period === "daily") {
            this.list.forEach((stats) => {
                stats.data = new Date(stats.data);
                stats.data = moment(stats.data).format("L");
                data.filter((val) => {
                    if (val.date === stats.data && stats.status === val.status) {
                        stats.contagem.push(val.status);
                    }
                });
            });
        } else {
            this.list.forEach((stats) => {
                data.filter((val) => {
                    if (val.date === stats.data && stats.status === val.status) {
                        stats.contagem.push(val.status);
                    }
                });
            });
        }
        this.list = this.list.filter((val) => {
            if (val.contagem.length > 0) {
                val.total = val.contagem.length;
                delete val.contagem;
                return val;
            }
        });
    }
    mountChart(labels, stats, data) {
        this.line.data.labels = labels;
        stats.filter((val) => {
            this.line.data.datasets.push({
                label: val,
                data: [],
                backgroundColor: `rgba(0, ${Math.round(
          Math.random() * 137
        )}, ${Math.round(Math.random() * 137)}, ${
          Math.random() * (1 - 0.2) + 0.2
        })`,
            });
        });
        labels.forEach((date) => {
            this.line.data.datasets.forEach((stats) => {
                let counter = data.filter((val) => {
                    if (val.date === date && stats.label === val.status) {
                        return val;
                    }
                });
                stats.data.push(counter.length);
            });
        });
    }
}
export class Contractschart extends Basechart {
    constructor(period) {
        super();
        this.period = period;
        this.labels = [];
        this.stats = [];
        this.progress = [];
        this.list = [];
    }
    mountLabels(dat = duplicateObject(dat)) {
        let labels = [];
        if (this.period === "daily") {
            dat.forEach((item) => {
                item.date = new Date(item.date);
                item.date = moment(item.date).format("L");
                labels.push(item.date);
            });
            labels = labels.filter((val, index, obj) => {
                return obj.indexOf(val) === index;
            });
            labels.sort((a, b) => {
                return new Date(a) - new Date(b);
            });
            return labels;
        } else {
            dat.forEach((item) => {
                item.date = new Date(item.date);
                item.date = moment(item.date).format("L");
                let data = item.date.split("/");
                item.date = data[0] + "/" + data[2];
                labels.push(item.date);
            });
            labels = labels.filter((val, index, obj) => {
                return obj.indexOf(val) === index;
            });
            labels.sort((a, b) => {
                return new Date(a) - new Date(b);
            });
            return labels;
        }
    }
    mountStats(dat) {
        let stats = [];
        dat.forEach((item) => {
            stats.push(item.progress);
        });
        stats = stats.filter((val, index, obj) => {
            return obj.indexOf(val) === index;
        });
        return stats;
    }
    mountList(labels, stats, data) {
        this.line.data.labels = labels;
        labels.forEach((dat) => {
            stats.filter((val) => {
                if (val === 100)
                    this.list.push({
                        data: dat,
                        status: val,
                        label: "Processadas",
                        contagem: [],
                    });
            });
        });
        if (this.period === "daily") {
            this.list.forEach((stats) => {
                stats.data = new Date(stats.data);
                stats.data = moment(stats.data).format("L");
                data.filter((val) => {
                    if (val.date === stats.data && stats.status === val.progress) {
                        stats.contagem.push(val.progress);
                    }
                });
            });
        } else {
            this.list.forEach((stats) => {
                data.filter((val) => {
                    if (val.date === stats.data && stats.status === val.progress) {
                        stats.contagem.push(val.progress);
                    }
                });
            });
        }
        this.list = this.list.filter((val) => {
            if (val.contagem.length > 0) {
                val.total = val.contagem.length;
                delete val.contagem;
                return val;
            }
        });
    }
    mountChart(labels, stats, data) {
        this.line.data.labels = labels;
        stats.filter((val) => {
            this.line.data.datasets.push({
                label: val,
                data: [],
                backgroundColor: `rgba(0, ${Math.round(
          Math.random() * 137
        )}, ${Math.round(Math.random() * 137)}, ${
          Math.random() * (1 - 0.2) + 0.2
        })`,
            });
        });
        labels.forEach((date) => {
            this.line.data.datasets.forEach((stats) => {
                let counter = data.filter((val) => {
                    if (val.date === date && stats.label === val.progress) {
                        return val;
                    }
                });
                stats.data.push(counter.length);
            });
        });
    }
}
export class Analyticschart extends Basechart {
    constructor(data, name) {
        super();
        this.mountChart(data, name);
    }
    mountChart(data, name) {
        data.forEach((val) => {
            this.line.data.labels = [name];
            this.line.data.datasets.push({
                label: val.label,
                data: [val.total],
                backgroundColor: `rgba(0, ${Math.round(
          Math.random() * 137
        )}, ${Math.round(Math.random() * 137)}, ${
          Math.random() * (1 - 0.2) + 0.2
        })`,
            });
        });
    }
}
export class Numdocschart extends Basechart {
    constructor(data, name) {
        super();
        this.mountChart(data, name);
        this.options = {
            ...this.options,
            legend: {
                display: false,
            },
        };
    }
    mountChart(data, name) {
        this.line.data.labels = data.map((val) => {
            return val.dados.label;
        });
        let count = data.map((val) => {
            return val.dados.total;
        });
        this.line.data.datasets.push({
            data: count,
            backgroundColor: `rgba(0, ${Math.round(
        Math.random() * 137
      )}, ${Math.round(Math.random() * 137)}, ${
        Math.random() * (1 - 0.2) + 0.2
      })`,
        });
    }
}
export class Classificationchart extends Basechart {
    constructor(data, name) {
        super();
        this.mountChart(data, name);
        this.options = {
            ...this.options,
            legend: {
                ...this.options,
                display: false,
            },
        };
    }
    mountChart(data, name) {
        this.line.data.labels = data[0];
        this.line.data.datasets.push({
            data: data[1],
            backgroundColor: ["#41B883", "#E46651", "#00D8FF", "#8f0a00", "#008099"],
        });
    }
}
export class Matriculachart extends Basechart {
    constructor(data, name) {
        super();
        this.mountChart(data, name);
        this.options = {
            ...this.options,
            legend: {
                ...this.options,
                display: false,
            },
        };
    }
    mountChart(data, name) {
        this.line.data.labels = data[0];
        this.line.data.datasets.push({
            data: data[1],
            backgroundColor: data[2],
        });
    }
}
export class Userschart extends Basechart {
    constructor(data, name) {
        super();
        this.mountChart(data, name);
        this.options = {
            ...this.options,
            legend: {
                ...this.options,
                display: true,
            },
        };
    }
    mountChart(data) {
        this.line.data.labels = data[0];

        data[1].forEach((val) => {
            this.line.data.datasets.push({
                label: val,
                data: data[2].map((val_) => {
                    return val_[val];
                }),
                backgroundColor: `#${Math.floor(Math.random() * 16777215).toString(
          16
        )}`,
            });
        });
    }
}
export class Annotatorsperson__piechart extends Basechart {
    constructor(data, name) {
        super();
        this.mountChart(data, name);
        this.options = {
            responsive: true,
            maintainAspectRatio: false,
            scales: {
                yAxes: [{
                    ticks: {
                        beginAtZero: true,
                    },
                }, ],
                xAxes: [{
                    ticks: {
                        beginAtZero: true,
                    },
                }, ],
            },
            legend: {
                display: false,
            },
        };
    }
    mountChart(data, name) {
        let count = [];
        for (let prop in data) {
            this.line.data.labels.push(prop);
            count.push(data[prop].length);
        }
        this.line.data.datasets.push({
            data: count,
            backgroundColor: ["#E46651", "#E46651", "#E46651", "#E46651", "#E46651", "#E46651", "#E46651", "#E46651", "#E46651", "#E46651", "#E46651"],
        });
        this.options = {
            responsive: true,
            maintainAspectRatio: false,
        };
    }
}
export class Annotatorscount__piechart extends Basechart {
    constructor(data, name) {
        super();
        this.mountChart(data, name);
        this.options = {
            responsive: true,
            maintainAspectRatio: false,
            scales: {
                yAxes: [{
                    ticks: {
                        beginAtZero: true,
                    },
                }, ],
                xAxes: [{
                    ticks: {
                        beginAtZero: true,
                    },
                }, ],
            },
            legend: {
                display: false,
            },
        };
    }
    mountChart(data, name) {
        this.line.data.labels = data.labels;
        this.line.data.datasets.push({
            data: data.count,
            backgroundColor: ["#41B883", "#41B883", "#41B883", "#41B883", "#41B883", "#41B883", "#41B883", "#41B883", "#41B883", "#41B883"],
        });
        this.options = {
            responsive: true,
            maintainAspectRatio: false,
        };
    }
}
export class Annotatorsfinished__piechart extends Basechart {
    constructor(data, name) {
        super();
        this.mountChart(data, name);
        this.options = {
            responsive: true,
            maintainAspectRatio: false,
            scales: {
                yAxes: [{
                    ticks: {
                        beginAtZero: true,
                    },
                }, ],
                xAxes: [{
                    ticks: {
                        beginAtZero: true,
                    },
                }, ],
            },
            legend: {
                display: false,
            },
        };
    }
    mountChart(data, name) {
        this.line.data.labels = data.labels;
        this.line.data.datasets.push({
            data: data.count,
            backgroundColor: ["#41B883", "#41B883", "#41B883", "#41B883", "#41B883", "#41B883", "#41B883", "#41B883", "#41B883", "#41B883"],
        });
        this.options = {
            responsive: true,
            maintainAspectRatio: false,
        };
    }
}